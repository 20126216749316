import { useState, useEffect, useRef } from "react";

import toast, { Toaster } from "react-hot-toast";

import { Share, Copy, Facebook, Twitter, Send, Linkedin } from "react-feather";

import { SiteInfo } from "@/types";
import { copyTextToClipboard } from "@/lib/util";

interface ShareProps {
  site: SiteInfo
}
export default function SharePage({ site }: ShareProps) {
  const pageLink = `https://${site.domain}`

  const twitterShare = () => {
    window.open(`https://twitter.com/share?text=${site.description}&url=${pageLink}`);
  }

  const facebookShare = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${pageLink}&quote=${site.description}`);
  }

  const whatsappShare = () => {
    window.open(`https://wa.me/?text=${site.description}%0A%0Ahttps%3A%2F%2F${site.domain}`);
  }

  const linkedInShare = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${site.domain}`);
  }

  return (<div className="inline-block w-full py-4 transition-all bg-white rounded-lg shadow-xl">
    <Toaster
      position="bottom-center"
      toastOptions={{
        className: '',
        style: {
          borderRadius: '30px',
          background: '#333',
          color: '#fff',
        },
      }}
    />
    <div className="flex flex-col items-center justify-center space-y-6">
      <div
        onClick={() => {
          toast.promise(copyTextToClipboard(pageLink), {
            loading: 'Copying',
            success: 'Link copied!',
            error: 'Error copying',
          });
        }}
        className="flex w-full px-3.5 py-3 cursor-pointer text-gray-500 bg-gray-100 hover:bg-gray-200 rounded-xl items-center justify-between">
        <p>https://{site.domain}</p>
        <Copy width={18} height={18} color="none" className="stroke-gray-500" />
      </div>
      <div className="flex w-full space-x-3">

        <div onClick={() => linkedInShare()} className="flex flex-col items-center justify-center w-full space-y-2 text-xs tracking-wide text-center text-gray-500">
          <div className="flex items-center justify-center w-12 h-12 m-auto text-center transition duration-300 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200">
            <Linkedin width={16} height={16} color="none" className="stroke-gray-500" />
          </div>
          <span>LinkedIn</span>
        </div>
        <div onClick={() => facebookShare()} className="flex flex-col items-center justify-center w-full space-y-2 text-xs tracking-wide text-center text-gray-500">
          <div className="flex items-center justify-center w-12 h-12 m-auto text-center transition duration-300 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200">
            <Facebook width={16} height={16} color="none" className="stroke-gray-500" />
          </div>
          <span>Facebook</span>
        </div>
        <div onClick={() => twitterShare()} className="flex flex-col items-center justify-center w-full space-y-2 text-xs tracking-wide text-center text-gray-500">
          <div className="flex items-center justify-center w-12 h-12 m-auto text-center transition duration-300 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200">
            <Twitter width={16} height={16} color="none" className="stroke-gray-500" />
          </div>
          <span>Twitter</span>
        </div>
        <div onClick={() => whatsappShare()} className="flex flex-col items-center justify-center w-full space-y-2 text-xs tracking-wide text-center text-gray-500">
          <div className="flex items-center justify-center w-12 h-12 m-auto text-center transition duration-300 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200">
            <Send width={16} height={16} color="none" className="stroke-gray-500" />
          </div>
          <span>Whatsapp</span>
        </div>
      </div>
    </div>
  </div>)
}