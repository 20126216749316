import cn from "clsx";
import Image from "next/image";
import { useState, useEffect } from "react";

import type { ComponentProps } from "react";
import type { WithClassName } from "@/types";

interface BlurImageProps extends WithClassName, ComponentProps<typeof Image> {
  alt: string;
}

export default function BlurImage(props: BlurImageProps) {
  const [isLoading, setLoading] = useState(true);

  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [props.src])

  return (
    <Image
      {...props}
      src={error ? 'data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==' : props.src}
      alt={props.alt}
      onError={() => setError(true)}
      className={cn(
        props.className,
        "duration-700 ease-in-out",
        isLoading
          ? "grayscale blur-2xl scale-110"
          : "grayscale-0 blur-0 scale-100"
      )}
      onLoadingComplete={() => setLoading(false)}
    />
  );
}